import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { CheckCircleIcon, XIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

import {
    useNotifications
} from '../contexts/notifications'

const Notifications = () => {
    const { state } = useNotifications()

    return (
        <div 
            className="bottom-20 left-6 right-6 max-w-max mx-auto fixed grid"
        >
            {state.length !== 0 && state.map(({ id, message, type }) => (
                <Item key={id} id={id} message={message} type={type}/>
            ))}
        </div>
    )
}

const Item = ({
    id,
    type,
    message,
}) => {
    const { dispatch } = useNotifications()

    const [ visible, setVisible ] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false)
        }, 5000)

        return () => clearTimeout(timer)
    }, [ visible, dispatch, id ])
  

    const wrapperClass = classNames(
        "transition-all duration-400 rounded-md px-4",
        {
            "mt-4 max-h-screen py-4": visible,
            "mt-0 max-h-0 py-0": !visible,
            "bg-green-100": type === 'success',
            "bg-red-100": type === 'error'
        }
    )

    const iconClass = classNames(
        "h-5 w-5",
        {
            "text-green-500": type === 'success',
            "text-red-500": type === 'error'
        }
    )

    const messageClass = classNames(
        "text-sm font-medium",
        {
            "text-green-800": type === 'success',
            "text-red-800": type === 'error'
        }
    )

    const buttonClass = classNames(
        "inline-flex rounded-md p-1.5 focus:outline-none",
        {
            "bg-green-100 text-green-500 hover:bg-green-200": type === 'success',
            "bg-red-100 text-red-500 hover:bg-red-200": type === 'error'
        }
    )

    const handleLeave = () => {
        return dispatch({
            type: 'REMOVE',
            payload: id
        })
    }

    return (
        <Transition
            appear={true}
            show={visible}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={handleLeave}
        >
                <div className={wrapperClass}>
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {type === 'success' && <CheckCircleIcon className={iconClass} aria-hidden="true" />}
                            {type === 'error' && <ExclamationCircleIcon className={iconClass} aria-hidden="true" />}
                        </div>
                        <div className="ml-3">
                            <p className={messageClass}>{message}</p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className={buttonClass}
                                    onClick={() => setVisible(false)}
                                >
                                <span className="sr-only">Dismiss</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
         </Transition>
    )
}

export default Notifications