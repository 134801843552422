import React from 'react'

import Notifications from './notifications'

const Layout = (props) => {
    return (
        <main className="bg-gray-50 min-h-screen w-full">
            {props.children}
            <Notifications/>
        </main>
    )
}

export default Layout