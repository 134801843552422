import React, { Fragment, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import isBrowser from '../validators/isBrowser'
import Loading from '../components/loading'
import { 
    Transition 
} from '@headlessui/react'

import { useAuth } from '../contexts/auth'

const AuthComponent = ({
    children,
    isProtected = false,
}) => {
    const [ loading, setLoading ] = useState(true)

    const {
        authService,
        authState
    } = useAuth()

    useEffect(() => {
        if ( 
            !isBrowser() 
            || authState.status === 'loading'
        ) return

        if ( loading ) {
            const handleAuth = async () => {
                const user = await authService.getToken()

                if (!!user === isProtected) {
                    return setLoading(false)
                }

                const redirect = isProtected ? '/' : '/app'

                navigate(redirect)
            }

            handleAuth()
        }
    }, [ authService, loading, isProtected, authState ])

    return (
        <>
        <Transition
            show={!loading}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
        <Transition
            show={loading}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500 delay-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Loading/>
        </Transition>
    </>
    )
}

export default AuthComponent